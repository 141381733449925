var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"room",style:(_vm.data.bg ? { backgroundImage: 'url(' + _vm.data.bg + ')' } : null)},[_c('div',{staticClass:"units-wrapper"},[(_vm.data.main)?_c('div',{staticClass:"unit",class:[
        _vm.data.main.isActive ? 'active' : null,
        'position-' + _vm.data.main.position
      ],style:({ backgroundImage: 'url(' + _vm.data.main.image + ')' })}):_vm._e(),(_vm.data.sub)?_c('div',{staticClass:"unit",class:[
        _vm.data.sub.isActive ? 'active' : null,
        'position-' + _vm.data.sub.position
      ],style:({ backgroundImage: 'url(' + _vm.data.sub.image + ')' })}):_vm._e()]),_c('div',{staticClass:"message-wrapper"},[(_vm.data.color)?_c("style",{tag:"component"},[_vm._v(" .game .message."+_vm._s(_vm.data.id)+"::before { background-color: "+_vm._s(_vm.data.color.bg)+"; color: "+_vm._s(_vm.data.color.fg)+"; } .game .message."+_vm._s(_vm.data.id)+" { background-color: "+_vm._s(_vm.data.color.bg)+"; color: "+_vm._s(_vm.data.color.fg)+"} ")]):_vm._e(),_c('div',{class:[
        'message',
        _vm.data.from && _vm.data.main && _vm.data.main.isActive
          ? 'arrow-' + _vm.data.main.position
          : null,
        _vm.data.from && _vm.data.sub && _vm.data.sub.isActive
          ? 'arrow-' + _vm.data.sub.position
          : null,
        _vm.data.id
      ],on:{"click":_vm.onClickMessage}},[_c('a',{staticClass:"btn-log",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onClickLog($event)}}},[_c('span',[_vm._v("ログ")])]),_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.data.message)}})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }