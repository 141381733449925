<template>
  <div
    class="room"
    :style="data.bg ? { backgroundImage: 'url(' + data.bg + ')' } : null"
  >
    <div class="units-wrapper">
      <div
        v-if="data.main"
        class="unit"
        :class="[
          data.main.isActive ? 'active' : null,
          'position-' + data.main.position
        ]"
        :style="{ backgroundImage: 'url(' + data.main.image + ')' }"
      ></div>
      <div
        v-if="data.sub"
        class="unit"
        :class="[
          data.sub.isActive ? 'active' : null,
          'position-' + data.sub.position
        ]"
        :style="{ backgroundImage: 'url(' + data.sub.image + ')' }"
      ></div>
    </div>
    <div class="message-wrapper">
      <!-- eslint-disable-next-line -->
      <component is="style" v-if="data.color">
        .game .message.{{ data.id }}::before { background-color:
        {{ data.color.bg }}; color: {{ data.color.fg }}; } .game .message.{{
          data.id
        }}
        { background-color: {{ data.color.bg }}; color: {{ data.color.fg }}}
      </component>
      <div
        :class="[
          'message',
          data.from && data.main && data.main.isActive
            ? 'arrow-' + data.main.position
            : null,
          data.from && data.sub && data.sub.isActive
            ? 'arrow-' + data.sub.position
            : null,
          data.id
        ]"
        @click="onClickMessage"
      >
        <a href="#" class="btn-log" @click.prevent.stop="onClickLog"
          ><span>ログ</span></a
        >
        <div class="text" v-html="data.message"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Room',
  props: {
    data: {
      type: Object,
      require: true,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  beforeCreate() {},
  mounted() {},
  methods: {
    onClickLog() {
      this.$emit('log');
    },
    onClickMessage(evt) {
      if (evt.srcElement.classList.contains('annotation')) {
        // annotation
        evt.srcElement.classList.toggle('active');
      } else if (this.data.next) {
        // next
        this.$emit('next');
      }
    },
    onOptionSelected(option) {
      this.$emit('next', option);
    }
  }
};
</script>

<style scoped>
/*
.btn-log {
  position: absolute;
  right: 15px;
  top: 15px;
  display: table;
  height: 50px;
  border: 5px solid #fb861f;
  background-color: #fb861f;
  border-radius: 50px;
  background-position: center left;
  background-repeat: no-repeat;
  line-height: 1;
  color: #fff;
  transition: 0.3s;
  background-image: url(/img/icon-btn-back.ac19639b.svg);
}
.btn-log span {
  font-size: 2rem;
  display: table-cell;
  vertical-align: middle;
  padding: 0px 15px 0px 45px;
}
*/
.game .message::before {
  background-image: none !important;
  top: 1px !important;
}
.game .message.arrow-left::before {
  mask-image: url(../img/game/img-message-arrow-left.svg) !important;
}
.game .message.arrow-center::before {
  mask-image: url(../img/game/img-message-arrow-right.svg) !important;
}
.game .message.arrow-right::before {
  mask-image: url(../img/game/img-message-arrow-right.svg) !important;
}
</style>
